import React from "react";
import "./techstack.css";

function Techstack() {
  return (
    <div className="techstack__container">
      <div className="techstack__left">
        <div className="techstack__LogoHolder">
          <img
            className="techstack__left_logo"
            alt=".net logo"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7d/Microsoft_.NET_logo.svg/2048px-Microsoft_.NET_logo.svg.png"
          />
        </div>
        <span className="techstack__left_text">
          <span className="techstack__left_text_Color">.Net </span>Full stack
          developer
        </span>
      </div>
      <div className="techstack__right">
        <div className="techstack__LogoHolderRight">
          {" "}
          <img
            className="techstack__right_logo"
            alt="MERN logo"
            src="https://sbr-technologies.com/wp-content/uploads/2021/06/mern.png"
          />
        </div>

        <span className="techstack__right_text">
          <span className="techstack__right_text_M">M.</span>
          <span className="techstack__right_text_E">E.</span>
          <span className="techstack__right_text_R">R.</span>
          <span className="techstack__right_text_N">N </span>
          stack developer
        </span>
      </div>
    </div>
  );
}

export default Techstack;
