import React from "react";
import "./header.css";

function Header() {
  return (
    <div className="header__bg">
      <div className="header__left">
        <div className="header__line1">
          <span className="header__hi">Hi, I'm</span>
        </div>
        <div className="header__line2">
          <span className="header__athul">Athul.dk</span>
        </div>
        <div className="header__line3">
          <span className="header__line3_creator">Creator.</span>
          </div>
      </div>

      <div className="header__right"></div>
    </div>
  );
}

export default Header;
