import './App.css';

import Header from "./header/header"
import Techstack from './techstack/techstack';
import Technologies from './technologies/Technologies'
import Experience from './experience/Experience'


function App() {
  return (
    <div className="App">
       <Header/>
       <Techstack/>
       <Technologies/>
       <Experience/>
    </div>
  );
}

export default App;
