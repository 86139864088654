import React from "react";
import "./Experience.css";

function Experience() {
  return (
    <>
      <div className="experience__title">
        <div className="experience__titleDetail">
          {" "}
          <img
            className="codeicon"
            src="https://cdn-icons-png.flaticon.com/512/759/759767.png"
          />
          <span className="experience__title_text">Experience</span>
        </div>
      </div>
      <div className="experience__container">
        <div className="experience_left">
          <div className="">
            <img
              className="experience_left_logo"
              alt="Accenture logo"
              src="https://cdn-icons-png.flaticon.com/512/6128/6128893.png"
            />
          </div>
          <div className="experience_Company">Accenture</div>
          <div className="experience_year">2018 to Present</div>
        </div>

        <div className="experience_middle">
          {" "}
          <div className="">
            <img
              className="experience_left_logo"
              alt="Accenture logo"
              src="https://companieslogo.com/img/orig/CNDT-e81dd5ee.png?t=1672901268"
            />
          </div>
          <div className="experience_Company">Conduent</div>
          <div className="experience_year">2016 to 2018</div>
        </div>

        <div className="experience_right">
        {" "}
          <div className="">
            <img
              className="experience_right_logo"
              alt="Xerox logo"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/Xerox_logo.svg/2560px-Xerox_logo.svg.png"
            />
          </div>
          <div className="experience_Company_right">Xerox</div>
          <div className="experience_year">2014 to 2016</div>
        </div>
      </div>
    </>
  );
}

export default Experience;
